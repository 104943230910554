import { useState } from "react";

export default function Form() {
  const [form, setForm] = useState({
    name: "",
    phone: "",
    jenis_sewa: "",
    jenis_mobil: "",
    start_date: "",
    end_date: "",
    route: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const message = `Halo Rainbow, saya ingin menyewa mobil dengan detail:\n *Nama:* ${form.name}\n *No. Telepon:* ${form.phone}\n *Jenis Sewa:* ${form.jenis_sewa}\n *Jenis Mobil:* ${form.jenis_mobil}\n *Start Date:* ${form.start_date}\n *End Date:* ${form.end_date}\n *Route:* ${form.route}`;
    window.location.replace(`https://wa.me/6281818437846?text=${encodeURIComponent(message)}`);
  }

  return (
    <section id="form" className="flex flex-col items-center justify-center w-full min-h-screen bg-slate-800 p-10 md:p-20">
      <h2 className="text-3xl poppins-semibold text-slate-50 text-left md:text-center">
        Pesan Sekarang Dan Nikmati
        <br /> Pelayanan Terbaik Dari Rainbow Rent Car
      </h2>
      <form
        action=""
        onSubmit={(e) => handleSubmit(e)}
        className="flex flex-col gap-5 w-full md:w-1/2 mt-10"
      >
        <div className="flex flex-col gap-2">
          <label htmlFor="name" className="text-slate-50 poppins-medium">
            Nama*
          </label>
          <input
            type="text"
            name="name"
            value={form.name}
            onChange={(e) => setForm({ ...form, name: e.currentTarget.value })}
            className="w-full p-3 rounded-lg bg-slate-50 text-slate-800"
            required
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="name" className="text-slate-50 poppins-medium">
            Nomor WhatsApp*
          </label>
          <input
            type="text"
            name="phone"
            value={form.phone}
            onChange={(e) => setForm({ ...form, phone: e.currentTarget.value })}
            className="w-full p-3 rounded-lg bg-slate-50 text-slate-800"
            required
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="jenis_sewa" className="text-slate-50 poppins-medium">
            Jenis Sewa
          </label>
          <select
            name="jenis_sewa"
            value={form.jenis_sewa}
            onChange={(e) =>
              setForm({ ...form, jenis_sewa: e.currentTarget.value })
            }
            className="w-full p-3 rounded-lg bg-slate-50 text-slate-800"
          >
            <option value="">Pilih Jenis Sewa</option>
            <option value="Harian Dengan Driver">Harian Dengan Driver</option>
            <option value="Wedding Car">Wedding Car</option>
            <option value="Bulanan Dengan Driver">Bulanan Dengan Driver</option>
            <option value="Bulanan Lepas Kunci">Bulanan Lepas Kunci</option>
          </select>
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="jenis_mobil" className="text-slate-50 poppins-medium">
            Jenis Mobil
          </label>
          <select
            name="jenis_mobil"
            value={form.jenis_mobil}
            onChange={(e) =>
              setForm({ ...form, jenis_mobil: e.currentTarget.value })
            }
            className="w-full p-3 rounded-lg bg-slate-50 text-slate-800"
          >
            <option value="">Pilih Jenis Mobil</option>
            <option value="Toyota Alphard Gen 2">Toyota Alphard Gen 2</option>
            <option value="Toyota Alphard Transformer">
              Toyota Alphard Transformer
            </option>
            <option value="Toyota Alphard Facelift">
              Toyota Alphard Facelift
            </option>
          </select>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          <div className="flex flex-col gap-2">
            <label htmlFor="name" className="text-slate-50 poppins-medium">
              Tanggal Mulai
            </label>
            <input
              type="date"
              name="start_date"
              value={form.start_date}
              onChange={(e) =>
                setForm({ ...form, start_date: e.currentTarget.value })
              }
              className="w-full p-3 rounded-lg bg-slate-50 text-slate-800"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="name" className="text-slate-50 poppins-medium">
              Tanggal Selesai
            </label>
            <input
              type="date"
              name="end_date"
              value={form.end_date}
              onChange={(e) =>
                setForm({ ...form, end_date: e.currentTarget.value })
              }
              className="w-full p-3 rounded-lg bg-slate-50 text-slate-800"
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
            <label htmlFor="route" className="text-slate-50 poppins-medium">
              Rute Perjalanan
            </label>
            <input
              type="text"
              name="route"
              value={form.route}
              onChange={(e) =>
                setForm({ ...form, route: e.currentTarget.value })
              }
              className="w-full p-3 rounded-lg bg-slate-50 text-slate-800"
              placeholder="Dalam kota Jakarta"
            />
          </div>
        <button
          type="submit"
          id="cta-form"
          className="w-full p-3 rounded-lg poppins-semibold bg-yellow-500 text-slate-800"
        >
          Kirim Pemesanan
        </button>
      </form>
      <p className="text-slate-50 mt-10">
        Masih bingung dengan pemesanan sewa mobil? Hubungi kami
      </p>
      <a
        href="https://wa.me/6281818437846"
        target="_blank"
        rel="noreferrer"
        id="cta-bottom"
        className="text-slate-50 text-xl poppins-bold flex gap-2 items-center justify-center mt-5"
      >
        <svg
          viewBox="0 0 20 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          fill="#000000"
          width={30}
          height={30}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            {" "}
            <title>whatsapp [#fff128]</title> <desc>Created with Sketch.</desc>{" "}
            <defs> </defs>{" "}
            <g
              id="Page-1"
              stroke="none"
              strokeWidth={1}
              fill="none"
              fillRule="evenodd"
            >
              {" "}
              <g
                id="Dribbble-Light-Preview"
                transform="translate(-300.000000, -7599.000000)"
                fill="#fff"
              >
                {" "}
                <g id="icons" transform="translate(56.000000, 160.000000)">
                  {" "}
                  <path
                    d="M259.821,7453.12124 C259.58,7453.80344 258.622,7454.36761 257.858,7454.53266 C257.335,7454.64369 256.653,7454.73172 254.355,7453.77943 C251.774,7452.71011 248.19,7448.90097 248.19,7446.36621 C248.19,7445.07582 248.934,7443.57337 250.235,7443.57337 C250.861,7443.57337 250.999,7443.58538 251.205,7444.07952 C251.446,7444.6617 252.034,7446.09613 252.104,7446.24317 C252.393,7446.84635 251.81,7447.19946 251.387,7447.72462 C251.252,7447.88266 251.099,7448.05372 251.27,7448.3478 C251.44,7448.63589 252.028,7449.59418 252.892,7450.36341 C254.008,7451.35771 254.913,7451.6748 255.237,7451.80984 C255.478,7451.90987 255.766,7451.88687 255.942,7451.69881 C256.165,7451.45774 256.442,7451.05762 256.724,7450.6635 C256.923,7450.38141 257.176,7450.3464 257.441,7450.44643 C257.62,7450.50845 259.895,7451.56477 259.991,7451.73382 C260.062,7451.85686 260.062,7452.43903 259.821,7453.12124 M254.002,7439 L253.997,7439 L253.997,7439 C248.484,7439 244,7443.48535 244,7449 C244,7451.18666 244.705,7453.21526 245.904,7454.86076 L244.658,7458.57687 L248.501,7457.3485 C250.082,7458.39482 251.969,7459 254.002,7459 C259.515,7459 264,7454.51465 264,7449 C264,7443.48535 259.515,7439 254.002,7439"
                    id="whatsapp-[#fff128]"
                  >
                    {" "}
                  </path>{" "}
                </g>{" "}
              </g>{" "}
            </g>{" "}
          </g>
        </svg>

        <span>+62 818-1843-7846</span>
      </a>
    </section>
  );
}
